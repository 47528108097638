import alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import persist from "@alpinejs/persist";
import collapse from "@alpinejs/collapse";
import smoothscroll from "smoothscroll-polyfill";

// @ts-expect-error weird typing error
alpine.plugin(intersect);
// @ts-expect-error weird typing error
alpine.plugin(persist);
// @ts-expect-error weird typing error
alpine.plugin(collapse);

Object.assign(window, { Alpine: alpine }).Alpine.start();

smoothscroll.polyfill();

import.meta.webpackHot?.accept(console.error);

import "./components/primary-menu";
import "./components/header-cart";
import "./components/slider";
import "./components/add-to-cart";
import "./components/quantity-input";
import "./components/product-category";
import "./components/product-popup";
import "./components/product";
import "./components/cart";
import "./components/checkout";
